<template>
  <div>
    <el-container>
      <CommonTree
        style="flex-shrink: 0"
        :treeData="treeData"
        :defaultProps="defaultProps"
        :searchTitle="searchTitle"
        :isShowdig="false"
        :showCheckbox="false"
        :popover-show="false"
        :treeExpand="false"
        :expandOnClickNode="false"
        :default-expanded-keys="[]"
        treeTitle="下发组织"
        :defaultCheckedKeys="defaultCheckedKeys"
        @include-down="includeDown"
        @getNodeClick="treeNodeClick"
      />
      <el-main>
        <head-layout
          head-title="任务下发详情列表"
          :head-btn-options="headBtnOptions"
          @head-close="closePage"
        />
        <grid-head-layout
          ref="gridHeadLayout"
          :search-columns="searchColumns"
          @grid-head-search="gridHeadSearch"
          @grid-head-empty="gridHeadEmpty"
        ></grid-head-layout>
        <grid-layout
          ref="gridLayOut"
          :table-options="tableOptions"
          :table-data="tableData"
          :table-loading="tableLoading"
          :data-total="page.total"
          :page="page"
          @page-current-change="handleCurrentChange"
          @page-size-change="handleSizeChange"
          @page-refresh-change="onLoad"
        >
          <!--          <template #customBtn="{ row, index }">-->
          <!--            <el-button-->
          <!--                size="small"-->
          <!--                type="text"-->
          <!--                @click.stop="rowView(row)"-->
          <!--            >-->
          <!--              查看-->
          <!--            </el-button>-->
          <!--          </template>-->
        </grid-layout>
      </el-main>
    </el-container>
  </div>
</template>

<script>
import HeadLayout from "@/views/components/layout/head-layout";
import GridLayout from "@/views/components/layout/grid-layout";
import CommonDialog from "@/components/CommonDialog";
import {getDetailPage, tree} from "@/api/reportDistribution";
import reportAllocationDialog
  from "@/views/business/reportManager/reportDistribution/distributionDialog.vue"
import CommonTree from "@/views/components/com_tree/index.vue";

export default {
  components: {
    CommonTree,
    HeadLayout,
    GridLayout,
    CommonDialog,
    reportAllocationDialog
  },
  data() {
    return {
      query: {},
      type:"",
      defaultCheckedKeys: [],
      treeData: [],
      unitId:"",
      defaultProps: {
        label: "fullName",
        value: "unitId",
        key: "unitId",
      },
      reportDistributionId:"",
      searchTitle: "fullName",
      dicData: [],
      isReportAllocationDialog: false,
      templateCode: "",
      bpmnOption: {},
      circulationVisible: false,
      bpmnVisible: false,
      page: {
        pageSize: 20,
        currentPage: 1,
        total: 0,
      },
      tableData: [],
      tableLoading: false,
      searchColumns: [
        {
          placeholder: "请输入人员名称",
          prop: "userNameList",
          span: 4,
        },
        {
          placeholder: "请选择状态",
          prop: "type",
          dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=success_failed",
          props: {
            label: "dictValue",
            value: "dictKey",
          },
          type: "select",
        },
      ]
    }
  },
  computed: {
    headBtnOptions() {
      let buttonBtn = [];
        buttonBtn.push(
          {
            label: "关闭",
            emit: "head-close",
            type: "button",
            icon: ""
          });

      return buttonBtn;
    },
    tableOptions() {
      return {
        index: true,
        menu: false,
        column: [
          {
            label: "组织名称",
            prop: "fullName",
            align: 'left',
            overHidden: true,
          },
          {
            label: "填报分类",
            prop: "acquisitionType",
            align: 'center',
            dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=acquisition_type",
            props: {
              label: "dictValue",
              value: "dictKey",
            },
            overHidden: true,
          },

          {
            label: "人员",
            prop: "userNameList",
            align: 'center',
            overHidden: true,
          },
          {
            label: "状态",
            prop: "type",
            align: 'center',
            overHidden: true,
            dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=success_failed",
            props: {
              label: "dictValue",
              value: "dictKey",
            },
          },
        ]
      }
    }
  },
  watch: {
    defaultCheckedKeys: {
      // immediate: true,
      handler: function (newVal) {
        if (newVal) {
          this.$nextTick(() => {
            // tree树结构点击会加上下面这个类名
            // 如果默认全部展开-那就会关闭
            document.querySelector(".el-tree-node__content").click();
          });
        }
      },
    },
  },
  methods: {
    closePage(){
      this.$router.$avueRouter.closeTag();
      this.$router.back();
    },
    initTree() {
      if (this.templateCode) {
        tree({templateCode: this.templateCode})
          .then((result) => {
            this.treeData = result.data.data;
            this.node = this.treeData[0];
            // this.$nextTick(() => {
            //   this.defaultCheckedKeys.push(this.treeData[0].unitId)
            // })
            this.treeLoading = false;
          })
          .catch((err) => {
            this.treeLoading = false;
          });
      } else {
        this.$message.warning("对应下发报表数据不存在!");
      }
    },
    // 点击左侧树节点
    treeNodeClick(node) {
      this.node = node;
      this.nodeId = node.unitId;
      this.unitId = this.nodeId;
      this.page.currentPage = 1;
      this.onLoad(this.page);
    },
    includeDown(e) {
      this.unitId = e.unitId;
      this.page.currentPage = 1;
      this.onLoad(this.page);
    },
    closeDialog() {
      this.isReportAllocationDialog = false
      this.onLoad(this.page)
    },
    gridHeadSearch(query) {
      this.page.currentPage = 1;
      this.type = query.type
      this.onLoad(this.page, query)
    },
    gridHeadEmpty(query) {
      this.page.currentPage = 1;
      this.onLoad(this.page, query)
    },
    //页数改变
    handleCurrentChange(e) {
      this.page.currentPage = e.currentPage
      this.onLoad(this.page, this.$refs.gridHeadLayout.searchForm)
    },
    //size改变
    handleSizeChange(e) {
      this.page.pageSize = e.pageSize
      this.onLoad(this.page, this.$refs.gridHeadLayout.searchForm)
    },
    onLoad(page, params = {}) {
      this.tableLoading = true;
      this.page = page;
      this.query.templateCode = this.templateCode
      this.query.unitId = this.unitId
      this.query.type = this.type;
      this.query.reportDistributionId = this.reportDistributionId
      getDetailPage(page.currentPage, page.pageSize, Object.assign(params, this.query)).then(res => {
        const data = res.data.data;
        this.tableData = data.records;
        this.$refs.gridLayOut.page.total = data.total;
        this.tableLoading = false;
      });
    },
  },
  created() {
    var parse = JSON.parse(this.$route.query.row);
    this.type = this.$route.query.type
    console.log(this.type,"this.type")
    this.templateCode = parse.templateCode;
    this.reportDistributionId = parse.id;
  },
  mounted() {
    console.log(this.$refs,"123456")
    this.$refs.gridHeadLayout.searchForm.type = this.type
    this.initTree();
    this.onLoad(this.page);
  },

}
</script>

<style scoped>

</style>
